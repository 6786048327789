import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios, {AxiosRequestConfig} from "axios";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App).use(ElementPlus).use(store).use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')

axios.interceptors.request.use((config: any) => {
    let token = window.localStorage.getItem("accessToken") //获取本地存储token
    if (token) {
        //将token放到请求头发送给服务器,将tokenkey放在请求头中
        config.headers.accessToken = token;
        //也可以这种写法
        // config.headers['accessToken'] = Token;
        return config;
    } else {
        token="None";
        return config;
    }
},  (error) => {
    // Do something with request error
    // return Promise.reject(error);
    console.log(error)
});
