let host = '';

let hostname = window.location.hostname
if (hostname === 'blog.debug.wang' || hostname ==='www.debug.wang') {
    host = 'https://blogapi.debug.wang'
} else {
    host = 'http://api.blog.debug.wang:8000'
}
console.log(host)
export default {
    host,    //地址
}
