import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: HomeView
    // },
    {
        path: '/',
        redirect: '/index'
    }, {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/IndexView.vue')
    }, {
        path: '/link',
        name: 'link',
        component: () => import(/* webpackChunkName: "link" */ '../views/FriendLinkView.vue')
    }, {
        path: '/tags',
        name: 'tags',
        component: () => import(/* webpackChunkName: "tags" */ '../views/TagsView.vue')
    }, {
        path: '/tag/:tid',
        name: 'tag',
        component: () => import(/* webpackChunkName: "tag" */ '../views/TagView.vue')
    }, {
        path: '/timeline',
        name: 'timeline',
        component: () => import(/* webpackChunkName: "timeline" */ '../views/TimeLineView.vue')
    }, {
        path: '/article/:aid',
        name: 'article',
        component: () => import(/* webpackChunkName: "article" */ '../views/ArticleView.vue'),
        props: true
    }, {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue'),
        props: true
    },{
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
        props: true
    }, {
        path:'/login',
        name:'login',
        component:()=> import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    },{
        path:'/register',
        name:'register',
        component:()=> import(/* webpackChunkName: "register" */ '../views/RegisterView.vue')
    },{
        path:'/retrieve',
        name:'retrieve',
        component:()=> import(/* webpackChunkName: "retrieve" */ '../views/RetrieveView.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
